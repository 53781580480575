/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { NotificationSettingsActions } from './notification-settings.actions';
import { initialNotificationSettingsState, NotificationSettingsState } from './notification-settings.store';

/**
 * notificationSettingsReducer
 *
 * @type {ActionReducer<NotificationSettingsState>}
 */
const _reducer: ActionReducer<NotificationSettingsState> = createReducer(
  initialNotificationSettingsState,

  on(
    NotificationSettingsActions.loadNotificationSettings,
    (state: NotificationSettingsState): NotificationSettingsState => ({
      ...state,
      notificationServiceDetailsLoading: true,
    }),
  ),

  on(
    NotificationSettingsActions.loadNotificationSettingsSuccess,
    (
      state: NotificationSettingsState,
      { notificationServiceDetails }: ReturnType<typeof NotificationSettingsActions.loadNotificationSettingsSuccess>,
    ): NotificationSettingsState => ({
      ...state,
      notificationServiceDetails,
      notificationServiceDetailsLoading: false,
    }),
  ),

  on(
    NotificationSettingsActions.loadNotificationSettingsFailure,
    (state: NotificationSettingsState): NotificationSettingsState => ({
      ...state,
      notificationServiceDetailsLoading: false,
    }),
  ),
);

/**
 * notificationSettingsReducer
 *
 * @export
 * @param {NotificationSettingsState} state
 * @param {Action} action
 * @returns {NotificationSettingsState}
 */
export function notificationSettingsReducer(state: NotificationSettingsState, action: Action): NotificationSettingsState {
  return _reducer(state, action);
}
