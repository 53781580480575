/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { IntelNotification, NotificationSearchRequest, NotificationSearchResponse } from '@ws1c/notification/model';
import { NotificationCenterActions } from './notification-center.actions';
import { initialNotificationCenterState, NotificationCenterState } from './notification-center.store';

const _reducer: ActionReducer<NotificationCenterState> = createReducer(
  initialNotificationCenterState,

  on(
    NotificationCenterActions.loadNotificationsServicesSuccess,
    (
      state: NotificationCenterState,
      { intelligenceServiceTypes }: ReturnType<typeof NotificationCenterActions.loadNotificationsServicesSuccess>,
    ): NotificationCenterState => ({
      ...state,
      intelligenceServiceTypes,
    }),
  ),

  on(
    NotificationCenterActions.getBannerNotificationsSuccess,
    (
      state: NotificationCenterState,
      { bannerNotifications }: ReturnType<typeof NotificationCenterActions.getBannerNotificationsSuccess>,
    ): NotificationCenterState => ({
      ...state,
      bannerNotifications,
    }),
  ),

  on(
    NotificationCenterActions.getBellTrayNotifications,
    (state: NotificationCenterState): NotificationCenterState => ({
      ...state,
      isLoadingBellTrayNotifications: true,
    }),
  ),

  on(
    NotificationCenterActions.getBellTrayNotificationsSuccess,
    (
      state: NotificationCenterState,
      { bellTrayNotifications }: ReturnType<typeof NotificationCenterActions.getBellTrayNotificationsSuccess>,
    ): NotificationCenterState => ({
      ...state,
      bellTrayNotifications,
      isLoadingBellTrayNotifications: false,
      readNotificationsMap: {
        ...state.readNotificationsMap,
        ...getNotificationReadMap(bellTrayNotifications),
      },
    }),
  ),

  on(
    NotificationCenterActions.getBellTrayNotificationsFailure,
    (state: NotificationCenterState): NotificationCenterState => ({
      ...state,
      isLoadingBellTrayNotifications: false,
    }),
  ),

  on(
    NotificationCenterActions.queryBellTrayNotifications,
    (
      state: NotificationCenterState,
      partialSearchRequest: ReturnType<typeof NotificationCenterActions.queryBellTrayNotifications>,
    ): NotificationCenterState => ({
      ...state,
      bellTrayNotificationSearchRequest: new NotificationSearchRequest({
        ...state.bellTrayNotificationSearchRequest,
        ...partialSearchRequest,
      }),
      isLoadingBellTrayNotifications: true,
    }),
  ),

  on(
    NotificationCenterActions.getCenterNotifications,
    (state: NotificationCenterState): NotificationCenterState => ({
      ...state,
      loadingNotificationsCenter: true,
    }),
  ),

  on(
    NotificationCenterActions.showBellTrayDismissAlert,
    (state: NotificationCenterState): NotificationCenterState => ({
      ...state,
      showBellTrayDismissAlert: true,
    }),
  ),

  on(
    NotificationCenterActions.getCenterNotificationsSuccess,
    (
      state: NotificationCenterState,
      { centerNotificationsSearchResponse }: ReturnType<typeof NotificationCenterActions.getCenterNotificationsSuccess>,
    ): NotificationCenterState => {
      const searchResponse = new NotificationSearchResponse({
        ...centerNotificationsSearchResponse,
      });
      if (state.isLoadingMoreNotifications) {
        searchResponse.results = state.centerNotificationsSearchResponse.results.concat(searchResponse.results);
      }
      return {
        ...state,
        centerNotificationsSearchResponse: searchResponse,
        readNotificationsMap: {
          ...state.readNotificationsMap,
          ...getNotificationReadMap(centerNotificationsSearchResponse.results),
        },
        loadingNotificationsCenter: false,
        isLoadingMoreNotifications: false,
      };
    },
  ),

  on(
    NotificationCenterActions.getCenterNotificationsFailure,
    (state: NotificationCenterState): NotificationCenterState => ({
      ...state,
      loadingNotificationsCenter: false,
      isLoadingMoreNotifications: false,
    }),
  ),

  on(
    NotificationCenterActions.queryCenterNotifications,
    (
      state: NotificationCenterState,
      partialSearchRequest: ReturnType<typeof NotificationCenterActions.queryCenterNotifications>,
    ): NotificationCenterState => ({
      ...state,
      centerNotificationSearchRequest: new NotificationSearchRequest({
        ...state.centerNotificationSearchRequest,
        ...partialSearchRequest,
        from: 0,
      }),
      centerNotificationsSearchResponse: undefined,
      loadingNotificationsCenter: true,
    }),
  ),

  on(
    NotificationCenterActions.loadMoreNotifications,
    (
      state: NotificationCenterState,
      pagedRequest: ReturnType<typeof NotificationCenterActions.loadMoreNotifications>,
    ): NotificationCenterState => ({
      ...state,
      centerNotificationSearchRequest: new NotificationSearchRequest({
        ...state.centerNotificationSearchRequest,
        ...pagedRequest,
      }),
      isLoadingMoreNotifications: true,
    }),
  ),

  on(
    NotificationCenterActions.getNotificationDetails,
    (state: NotificationCenterState): NotificationCenterState => ({
      ...state,
      isLoadingNotificationDetails: true,
    }),
  ),

  on(
    NotificationCenterActions.getNotificationDetailsSuccess,
    (
      state: NotificationCenterState,
      { notification }: ReturnType<typeof NotificationCenterActions.getNotificationDetailsSuccess>,
    ): NotificationCenterState => ({
      ...state,
      notificationDetails: notification,
      isLoadingNotificationDetails: false,
    }),
  ),

  on(
    NotificationCenterActions.getNotificationDetailsFailure,
    (state: NotificationCenterState): NotificationCenterState => ({
      ...state,
      isLoadingNotificationDetails: false,
    }),
  ),

  on(
    NotificationCenterActions.getUnseenNotificationsCountSuccess,
    (
      state: NotificationCenterState,
      { totalUnseenNotifications }: ReturnType<typeof NotificationCenterActions.getUnseenNotificationsCountSuccess>,
    ): NotificationCenterState => ({
      ...state,
      totalUnseenNotifications,
    }),
  ),

  on(
    NotificationCenterActions.toggleSelectedNotification,
    (
      state: NotificationCenterState,
      { notificationId, selected }: ReturnType<typeof NotificationCenterActions.toggleSelectedNotification>,
    ): NotificationCenterState => {
      const selectedNotificationIds = new Set(state.selectedNotificationIds);
      if (selected) {
        selectedNotificationIds.add(notificationId);
      } else {
        selectedNotificationIds.delete(notificationId);
      }
      return {
        ...state,
        selectedNotificationIds,
      };
    },
  ),

  on(
    NotificationCenterActions.toggleReadNotificationsSuccess,
    (
      state: NotificationCenterState,
      { notificationIds, read, resetSelectedNotifications }: ReturnType<typeof NotificationCenterActions.toggleReadNotificationsSuccess>,
    ): NotificationCenterState => {
      const readNotificationsMap = notificationIds.reduce((map: Record<string, boolean>, id: string): Record<string, boolean> => {
        map[id] = read;
        return map;
      }, {});
      return {
        ...state,
        selectedNotificationIds: resetSelectedNotifications ? new Set() : state.selectedNotificationIds,
        readNotificationsMap: {
          ...state.readNotificationsMap,
          ...readNotificationsMap,
        },
      };
    },
  ),
);

/**
 * getNotificationReadMap
 * @param {IntelNotification[]} notifications
 * @returns {Record<string, boolean>}
 */
function getNotificationReadMap(notifications: IntelNotification[]): Record<string, boolean> {
  return notifications.reduce((map: Record<string, boolean>, notification: IntelNotification): Record<string, boolean> => {
    map[notification.id] = notification.read;
    return map;
  }, {});
}

/**
 * Notification Reducer
 *
 * @export
 * @param {NotificationCenterState} state
 * @param {Action} action
 * @returns {NotificationCenterState}
 */
export function notificationCenterReducer(state: NotificationCenterState, action: Action): NotificationCenterState {
  return _reducer(state, action);
}
