/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { NotificationTagDelivery } from './notification-tag-delivery.model';

/**
 * NotificationTag
 *
 * @export
 * @class NotificationTag
 */
@Serializable
export class NotificationTag {
  @JsonProperty('tag_id')
  public tagId: string = undefined;

  @JsonProperty('name')
  public tagName: string = undefined;

  @JsonProperty('description')
  public tagDescription: string = undefined;

  @JsonProperty({ name: 'delivery_type_settings', cls: NotificationTagDelivery })
  public tagDeliveries: NotificationTagDelivery[] = undefined;

  /**
   * Creates an instance of NotificationTag.
   * @param {...Array<Partial<NotificationTag>>} args
   * @memberof NotificationTag
   */
  constructor(...args: Array<Partial<NotificationTag>>) {
    Object.assign(this, ...args);
  }
}
