/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { enumConverterFactory, IntelligenceServiceType, IntelligenceServiceTypeLabel } from '@ws1c/intelligence-models';

import { NotificationTag } from './notification-tag.model';

/**
 * NotificationServiceDetails
 *
 * @export
 * @class NotificationServiceDetails
 */
@Serializable
export class NotificationServiceDetails {
  @JsonProperty({ name: 'service_type', customConverter: enumConverterFactory(IntelligenceServiceType) })
  public serviceType: IntelligenceServiceType = undefined;

  @JsonProperty({ name: 'notification_subscription_tag_views', cls: NotificationTag })
  public tags: NotificationTag[] = undefined;

  /**
   * Creates an instance of NotificationServiceDetails.
   * @param {...Array<Partial<NotificationServiceDetails>>} args
   * @memberof NotificationServiceDetails
   */
  constructor(...args: Array<Partial<NotificationServiceDetails>>) {
    Object.assign(this, ...args);
  }

  /**
   * serviceName
   * @readonly
   * @type {string}
   * @memberof NotificationServiceDetails
   */
  public get serviceName(): string {
    return IntelligenceServiceTypeLabel[this.serviceType];
  }

  /**
   * fullServiceName
   * @readonly
   * @type {string}
   * @memberof NotificationServiceDetails
   */
  public get fullServiceName(): string {
    return IntelligenceServiceTypeLabel[`${this.serviceType}_FULL`];
  }
}
