/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory, IntelligenceServiceType } from '@ws1c/intelligence-models';
import { NotificationTagDelivery } from './notification-tag-delivery.model';

/**
 * NotificationServiceDetailsUpdateRequest
 *
 * @export
 * @class NotificationServiceDetailsUpdateRequest
 */
@Serializable
export class NotificationServiceDetailsUpdateRequest {
  @JsonProperty({ name: 'service_type', customConverter: enumConverterFactory(IntelligenceServiceType) })
  public serviceType: IntelligenceServiceType = undefined;

  @JsonProperty('tag_id')
  public tagId: string = undefined;

  @JsonProperty({ name: 'delivery_type_settings', cls: NotificationTagDelivery })
  public tagDeliveries: NotificationTagDelivery[] = undefined;

  /**
   * Creates an instance of NotificationServiceDetailsUpdateRequest.
   * @param {...Array<Partial<NotificationServiceDetailsUpdateRequest>>} args
   * @memberof NotificationServiceDetailsUpdateRequest
   */
  constructor(...args: Array<Partial<NotificationServiceDetailsUpdateRequest>>) {
    Object.assign(this, ...args);
  }
}
