/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { DataGridComponent, SortOn } from '@dpa/ui-common';

import { COLUMN_NAMES, IntelligenceServiceType } from '@ws1c/intelligence-models';
import { IntelNotification, NotificationSearchRequest, NotificationSearchResponse } from '@ws1c/notification/model';

/**
 * NotificationState
 * @export
 * @interface NotificationState
 */
export interface NotificationCenterState {
  // Common
  intelligenceServiceTypes: IntelligenceServiceType[];
  // Banner
  bannerNotifications: IntelNotification[];
  // Bell tray
  isLoadingBellTrayNotifications: boolean;
  bellTrayNotificationSearchRequest: NotificationSearchRequest;
  bellTrayNotifications: IntelNotification[];
  totalUnseenNotifications: number;
  showBellTrayDismissAlert: boolean;
  // Notification center
  loadingNotificationsCenter: boolean;
  isLoadingMoreNotifications: boolean;
  centerNotificationSearchRequest: NotificationSearchRequest;
  centerNotificationsSearchResponse: NotificationSearchResponse;
  selectedNotificationIds: Set<string>;
  readNotificationsMap: Record<string, boolean>;
  notificationDetails: IntelNotification;
  isLoadingNotificationDetails: boolean;
}

export const initialNotificationCenterState: NotificationCenterState = {
  intelligenceServiceTypes: [],
  bannerNotifications: [],
  isLoadingBellTrayNotifications: false,
  bellTrayNotificationSearchRequest: new NotificationSearchRequest(),
  bellTrayNotifications: [],
  totalUnseenNotifications: 0,
  showBellTrayDismissAlert: false,
  selectedNotificationIds: new Set(),
  loadingNotificationsCenter: false,
  isLoadingMoreNotifications: false,
  centerNotificationsSearchResponse: null,
  centerNotificationSearchRequest: new NotificationSearchRequest({
    size: DataGridComponent.DEFAULT_PAGE_SIZE,
    sortOns: [
      new SortOn({
        by: COLUMN_NAMES.byName.created_at,
        reverse: true,
      }),
    ],
  }),
  readNotificationsMap: {},
  notificationDetails: null,
  isLoadingNotificationDetails: false,
};
