/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { AppNotification, AppNotificationSeverity, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import {
  dateFormatConverter,
  defaultValueConverterFactory,
  enumConverterFactory,
  IntelligenceServiceType,
  IntelligenceServiceTypeLabel,
} from '@ws1c/intelligence-models';
import { NotificationActionTypes } from './notification-action-types.enum';
import { NotificationStatus } from './notification-status.enum';

/**
 * IntelNotification
 *
 * @export
 * @class IntelNotification
 */
@Serializable
export class IntelNotification implements AppNotification {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('title')
  public title: string = undefined;

  @JsonProperty('body')
  public body: string = undefined;

  @JsonProperty({ name: 'service_type', customConverter: enumConverterFactory(IntelligenceServiceType) })
  public serviceType: IntelligenceServiceType = undefined;

  @JsonProperty({ name: 'severity', customConverter: enumConverterFactory(AppNotificationSeverity) })
  public severity: AppNotificationSeverity = undefined;

  @JsonProperty('dismissable')
  public dismissable: boolean = false;

  @JsonProperty('read')
  public read: boolean = false;

  @JsonProperty('dismissed')
  public dismissed: boolean = false;

  @JsonProperty('action_title')
  public actionTitle: string = undefined;

  @JsonProperty('action_url')
  public actionUrl: string = undefined;

  @JsonProperty({ name: 'action_page', customConverter: enumConverterFactory(NotificationActionTypes) })
  public actionPage: NotificationActionTypes = undefined;

  @JsonProperty({ name: 'action_page_param_map', customConverter: defaultValueConverterFactory() })
  public actionPageParams: GenericObject = undefined;

  @JsonProperty({ name: 'state', customConverter: enumConverterFactory(NotificationStatus) })
  public state: NotificationStatus = undefined;

  @JsonProperty({ name: 'expires_at', customConverter: dateFormatConverter, excludeToJson: true })
  public expiresAt: number = undefined;

  @JsonProperty({ name: 'message-tag-ids', excludeToJson: true })
  public messageTagIds: string[] = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'modified_by', excludeToJson: true })
  public modifiedBy: string = undefined;

  /**
   * Creates an instance of IntelNotification.
   * @param {...Array<Partial<IntelNotification>>} args
   * @memberof IntelNotification
   */
  constructor(...args: Array<Partial<IntelNotification>>) {
    Object.assign(this, ...args);
  }

  /**
   * serviceName
   * @readonly
   * @type {string}
   * @memberof IntelNotification
   */
  public get serviceName(): string {
    return IntelligenceServiceTypeLabel[this.serviceType];
  }
}
