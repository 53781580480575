/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * NotificationActionTypes
 *
 * @export
 * @enum {string}
 */
export enum NotificationActionTypes {
  ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
  DASHBOARD_HOME = 'DASHBOARD_HOME',
  DASHBOARD = 'DASHBOARD',
  END_USER_MANAGEMENT = 'END_USER_MANAGEMENT',
  END_USER_MANAGEMENT_DIRECTORY_SETTINGS = 'END_USER_MANAGEMENT_DIRECTORY_SETTINGS',
  INCIDENT_HOME = 'INCIDENT_HOME',
  INCIDENT_RCA = 'INCIDENT_RCA',
  INSIGHT = 'INSIGHT',
  INTEGRATIONS_INBOUND = 'INTEGRATIONS_INBOUND',
  PROFILE = 'PROFILE',
  REPORT_HOME = 'REPORT_HOME',
  REPORT = 'REPORT',
}
