/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ActionReducerMap } from '@ngrx/store';

import { CoreAppState } from '@ws1c/intelligence-core';
import { notificationCenterReducer } from './notification-center/notification-center.reducer';
import { NotificationCenterState } from './notification-center/notification-center.store';
import { notificationSettingsReducer } from './notification-settings/notification-settings.reducer';
import { NotificationSettingsState } from './notification-settings/notification-settings.store';

/**
 * NotificationState
 *
 * @export
 * @interface NotificationState
 */
export interface NotificationState extends CoreAppState {
  notificationCenter: NotificationCenterState;
  notificationSettings: NotificationSettingsState;
}

/**
 * Notification state reducer map
 * @type {ActionReducerMap<Partial<NotificationState>>}
 */
export const notificationReducerMap: ActionReducerMap<Partial<NotificationState>> = {
  notificationCenter: notificationCenterReducer,
  notificationSettings: notificationSettingsReducer,
};
