/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * NotificationActionType
 *
 * @export
 * @enum {string}
 */
export enum NotificationActionType {
  DISMISS = 'DISMISS',
  READ = 'READ',
  UNREAD = 'UNREAD',
}
