/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * NotificationDeliveryType
 *
 * @export
 * @enum {string}
 */
export enum NotificationDeliveryType {
  INAPP = 'INAPP',
  EMAIL = 'EMAIL',
}
