/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { UserPreferenceUIPreferenceSelectors } from '@ws1c/intelligence-core';
import { IntelligenceServiceType } from '@ws1c/intelligence-models';
import { NotificationSearchRequest, NotificationSearchResponse } from '@ws1c/notification/model';
import { IntelNotification } from '@ws1c/notification/model/notification.model';
import { selectNotificationState } from '@ws1c/notification/store/notification.selector';
import { NotificationState } from '@ws1c/notification/store/notification.store';
import { NotificationCenterState } from './notification-center.store';

/**
 * NotificationCenterSelectors
 *
 * @export
 * @class NotificationCenterSelectors
 */
export class NotificationCenterSelectors {
  /**
   * getNotificationCenterState
   * @static
   * @type {MemoizedSelector<NotificationState, NotificationCenterState>}
   * @memberof NotificationCenterSelectors
   */
  public static getNotificationCenterState: MemoizedSelector<NotificationState, NotificationCenterState> = createSelector(
    selectNotificationState,
    (state: NotificationState) => state?.notificationCenter,
  );

  /**
   * intelligenceServiceTypes
   * @static
   * @type {MemoizedSelector<CoreAppState, IntelligenceServiceType[]>}
   * @memberof NotificationCenterSelectors
   */
  public static intelligenceServiceTypes: MemoizedSelector<NotificationState, IntelligenceServiceType[]> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.intelligenceServiceTypes,
  );

  /**
   * bannerNotifications
   * @static
   * @type {MemoizedSelector<NotificationState, IntelNotification[]>}
   * @memberof NotificationCenterSelectors
   */
  public static bannerNotifications: MemoizedSelector<NotificationState, IntelNotification[]> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.bannerNotifications,
  );

  /**
   * centerNotificationSearchRequest
   * @static
   * @type {MemoizedSelector<NotificationState, NotificationSearchRequest>}
   * @memberof NotificationCenterSelectors
   */
  public static centerNotificationSearchRequest: MemoizedSelector<NotificationState, NotificationSearchRequest> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.centerNotificationSearchRequest,
  );

  /**
   * centerNotificationsSearchResponse
   * @static
   * @type {MemoizedSelector<NotificationState, NotificationSearchResponse>}
   * @memberof NotificationCenterSelectors
   */
  public static centerNotificationsSearchResponse: MemoizedSelector<NotificationState, NotificationSearchResponse> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.centerNotificationsSearchResponse,
  );

  /**
   * loadingNotificationsCenter
   * @static
   * @type {MemoizedSelector<NotificationState, boolean>}
   * @memberof NotificationCenterSelectors
   */
  public static loadingNotificationsCenter: MemoizedSelector<NotificationState, boolean> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.loadingNotificationsCenter,
  );

  /**
   * isLoadingMoreNotifications
   * @static
   * @type {MemoizedSelector<NotificationState, boolean>}
   * @memberof NotificationCenterSelectors
   */
  public static isLoadingMoreNotifications: MemoizedSelector<NotificationState, boolean> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.isLoadingMoreNotifications,
  );

  /**
   * isLoadingBellTrayNotifications
   * @static
   * @type {MemoizedSelector<NotificationState, boolean>}
   * @memberof NotificationCenterSelectors
   */
  public static isLoadingBellTrayNotifications: MemoizedSelector<NotificationState, boolean> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.isLoadingBellTrayNotifications,
  );

  /**
   * bellTrayNotificationSearchRequest
   * @static
   * @type {MemoizedSelector<NotificationState, NotificationSearchRequest>}
   * @memberof NotificationCenterSelectors
   */
  public static bellTrayNotificationSearchRequest: MemoizedSelector<NotificationState, NotificationSearchRequest> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.bellTrayNotificationSearchRequest,
  );

  /**
   * bellTrayNotifications
   * @static
   * @type {MemoizedSelector<NotificationState, IntelNotification[]>}
   * @memberof NotificationCenterSelectors
   */
  public static bellTrayNotifications: MemoizedSelector<NotificationState, IntelNotification[]> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.bellTrayNotifications,
  );

  /**
   * totalUnseenNotifications
   * @static
   * @type {MemoizedSelector<NotificationState, number>}
   * @memberof NotificationCenterSelectors
   */
  public static totalUnseenNotifications: MemoizedSelector<NotificationState, number> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.totalUnseenNotifications,
  );

  /**
   * showBellTrayDismissAlert
   * @static
   * @type {MemoizedSelector<NotificationState, boolean>}
   * @memberof NotificationCenterSelectors
   */
  public static showBellTrayDismissAlert: MemoizedSelector<NotificationState, boolean> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    UserPreferenceUIPreferenceSelectors.isNotificationBellTrayAlertDismissed,
    (state: NotificationCenterState, isNotificationBellTrayAlertDismissed: boolean) => {
      return state.showBellTrayDismissAlert && !isNotificationBellTrayAlertDismissed;
    },
  );

  /**
   * selectedNotificationIds
   * @static
   * @type {MemoizedSelector<NotificationState, Set<string>>}
   * @memberof NotificationCenterSelectors
   */
  public static selectedNotificationIds: MemoizedSelector<NotificationState, Set<string>> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.selectedNotificationIds,
  );

  /**
   * hasSelectedNotifications
   * @static
   * @type {MemoizedSelector<NotificationState, boolean>}
   * @memberof NotificationCenterSelectors
   */
  public static hasSelectedNotifications: MemoizedSelector<NotificationState, boolean> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.selectedNotificationIds.size > 0,
  );

  /**
   * readNotificationsMap
   * @static
   * @type {MemoizedSelector<NotificationState, Record<string, boolean>}
   * @memberof NotificationCenterSelectors
   */
  public static readNotificationsMap: MemoizedSelector<NotificationState, Record<string, boolean>> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.readNotificationsMap,
  );

  /**
   * isLoadingNotificationDetails
   * @static
   * @type {MemoizedSelector<NotificationState, boolean}
   * @memberof NotificationCenterSelectors
   */
  public static isLoadingNotificationDetails: MemoizedSelector<NotificationState, boolean> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.isLoadingNotificationDetails,
  );

  /**
   * notificationDetails
   * @static
   * @type {MemoizedSelector<NotificationState, IntelNotification}
   * @memberof NotificationCenterSelectors
   */
  public static notificationDetails: MemoizedSelector<NotificationState, IntelNotification> = createSelector(
    NotificationCenterSelectors.getNotificationCenterState,
    (state: NotificationCenterState) => state.notificationDetails,
  );
}
