/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { IntelNotification } from './notification.model';

/**
 * Notification Search Response
 *
 * @export
 * @class NotificationSearchResponse
 */
@Serializable
export class NotificationSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: IntelNotification })
  public results: IntelNotification[] = undefined;

  /**
   * Initializes instance of NotificationSearchResponse
   * @param {Array<Partial<NotificationSearchResponse>>} args
   * @memberof NotificationSearchResponse
   */
  constructor(...args: Array<Partial<NotificationSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
