/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { NotificationServiceDetails } from '@ws1c/notification/model';

/**
 * NotificationSettingsState
 * @export
 * @interface NotificationSettingsState
 */
export interface NotificationSettingsState {
  notificationServiceDetailsLoading: boolean;
  notificationServiceDetails: NotificationServiceDetails[];
}

export const initialNotificationSettingsState: NotificationSettingsState = {
  notificationServiceDetailsLoading: false,
  notificationServiceDetails: undefined,
};
