/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * NotificationStatus
 *
 * @export
 * @enum {string}
 */
export enum NotificationStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  REVOKED = 'REVOKED',
}
