/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { PagedRequest } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { IntelligenceServiceType } from '@ws1c/intelligence-models';
import { IntelNotification, NotificationSearchRequest, NotificationSearchResponse, NotificationViewType } from '@ws1c/notification/model';

/**
 *
 * Define actions for Notification
 * @export
 * @class NotificationCenterActions
 */
@Injectable()
export class NotificationCenterActions {
  public static readonly CATEGORY = '[NOTIFICATION]';

  private static type = (actionName: string): string => `${NotificationCenterActions.CATEGORY} ${actionName}`;

  public static loadNotificationsServices = createAction(NotificationCenterActions.type('LOAD_NOTIFICATIONS_SERVICES'));

  public static loadNotificationsServicesSuccess = createAction(
    NotificationCenterActions.type('LOAD_NOTIFICATIONS_SERVICES_SUCCESS'),
    props<{ intelligenceServiceTypes: IntelligenceServiceType[] }>(),
  );

  public static loadNotificationsServicesFailure = createAction(NotificationCenterActions.type('LOAD_NOTIFICATIONS_SERVICES_FAILURE'));

  public static getBannerNotifications = createAction(NotificationCenterActions.type('GET_BANNER_NOTIFICATIONS'));

  public static getBannerNotificationsSuccess = createAction(
    NotificationCenterActions.type('GET_BANNER_NOTIFICATIONS_SUCCESS'),
    props<{ bannerNotifications: IntelNotification[] }>(),
  );

  public static getBannerNotificationsFailure = createAction(NotificationCenterActions.type('GET_BANNER_NOTIFICATIONS_FAILURE'));

  public static getCenterNotifications = createAction(NotificationCenterActions.type('GET_CENTER_NOTIFICATIONS'));

  public static getCenterNotificationsSuccess = createAction(
    NotificationCenterActions.type('GET_CENTER_NOTIFICATIONS_SUCCESS'),
    props<{ centerNotificationsSearchResponse: NotificationSearchResponse }>(),
  );

  public static getCenterNotificationsFailure = createAction(NotificationCenterActions.type('GET_CENTER_NOTIFICATIONS_FAILURE'));

  public static queryCenterNotifications = createAction(
    NotificationCenterActions.type('QUERY_CENTER_NOTIFICATIONS'),
    props<Partial<NotificationSearchRequest>>(),
  );

  public static loadMoreNotifications = createAction(NotificationCenterActions.type('LOAD_MORE_NOTIFICATIONS'), props<PagedRequest>());

  // This action refreshes the bell tray notifications without showing spinner.
  public static refreshBellTrayNotifications = createAction(NotificationCenterActions.type('REFRESH_BELL_TRAY_NOTIFICATIONS'));

  public static getBellTrayNotifications = createAction(NotificationCenterActions.type('GET_BELL_TRAY_NOTIFICATIONS'));

  public static getBellTrayNotificationsSuccess = createAction(
    NotificationCenterActions.type('GET_BELL_TRAY_NOTIFICATIONS_SUCCESS'),
    props<{ bellTrayNotifications: IntelNotification[] }>(),
  );

  public static getBellTrayNotificationsFailure = createAction(NotificationCenterActions.type('GET_BELL_TRAY_NOTIFICATIONS_FAILURE'));

  public static queryBellTrayNotifications = createAction(
    NotificationCenterActions.type('QUERY_BELL_TRAY_NOTIFICATIONS'),
    props<Partial<NotificationSearchRequest>>(),
  );

  public static showBellTrayDismissAlert = createAction(NotificationCenterActions.type('SHOW_BELL_TRAY_DISMISS_ALERT'));

  public static getNotificationDetails = createAction(NotificationCenterActions.type('GET_NOTIFICATION_DETAILS'), props<{ id: string }>());

  public static getNotificationDetailsSuccess = createAction(
    NotificationCenterActions.type('GET_NOTIFICATION_DETAILS_SUCCESS'),
    props<{ notification: IntelNotification }>(),
  );

  public static getNotificationDetailsFailure = createAction(NotificationCenterActions.type('GET_NOTIFICATION_DETAILS_FAILURE'));

  public static dismissNotifications = createAction(
    NotificationCenterActions.type('DISMISS_NOTIFICATIONS'),
    props<{
      notificationIds: string[];
      view: NotificationViewType;
    }>(),
  );

  public static dismissNotificationsSuccess = createAction(NotificationCenterActions.type('DISMISS_NOTIFICATIONS_SUCCESS'));

  public static dismissNotificationsFailure = createAction(NotificationCenterActions.type('DISMISS_NOTIFICATIONS_FAILURE'));

  public static toggleReadNotifications = createAction(
    NotificationCenterActions.type('TOGGLE_READ_NOTIFICATIONS'),
    props<{
      notificationIds?: string[];
      read: boolean;
    }>(),
  );

  public static toggleReadNotificationsSuccess = createAction(
    NotificationCenterActions.type('TOGGLE_READ_NOTIFICATIONS_SUCCESS'),
    props<{
      notificationIds: string[];
      read: boolean;
      resetSelectedNotifications: boolean;
    }>(),
  );

  public static toggleReadNotificationsFailure = createAction(NotificationCenterActions.type('TOGGLE_READ_NOTIFICATIONS_FAILURE'));

  public static getUnseenNotificationsCount = createAction(NotificationCenterActions.type('GET_UNSEEN_NOTIFICATIONS_COUNT'));

  public static getUnseenNotificationsCountSuccess = createAction(
    NotificationCenterActions.type('GET_UNSEEN_NOTIFICATIONS_COUNT_SUCCESS'),
    props<{ totalUnseenNotifications: number }>(),
  );

  public static getUnseenNotificationsCountFailure = createAction(NotificationCenterActions.type('GET_UNSEEN_NOTIFICATIONS_COUNT_FAILURE'));

  // Refreshes unseen count without throttle
  public static refreshUnseenNotificationsCount = createAction(NotificationCenterActions.type('REFRESH_UNSEEN_NOTIFICATIONS_COUNT'));

  public static toggleSelectedNotification = createAction(
    NotificationCenterActions.type('TOGGLE_SELECTED_NOTIFICATION'),
    props<{
      notificationId: string;
      selected: boolean;
    }>(),
  );

  public static openNotificationActionPageOrUrl = createAction(
    NotificationCenterActions.type('OPEN_NOTIFICATION_ACTION_PAGE_OR_URL'),
    props<{ notification: IntelNotification }>(),
  );

  public static handleRedirectFromEmail = createAction(NotificationCenterActions.type('HANDLE_REDIRECT_FROM_EMAIL'));
}
