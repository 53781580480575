/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createFeatureSelector, MemoizedSelector } from '@ngrx/store';

import { NOTIFICATION_STORE_KEY } from './notification-store-key';
import { NotificationState } from './notification.store';

export const selectNotificationState: MemoizedSelector<NotificationState, NotificationState> =
  createFeatureSelector<NotificationState>(NOTIFICATION_STORE_KEY);
