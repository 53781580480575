/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * NotificationViewType
 *
 * @export
 * @enum {string}
 */
export enum NotificationViewType {
  BANNER = 'BANNER',
  BELL_TRAY = 'BELL_TRAY',
  CENTER = 'NOTIFICATION_CENTER',
}
