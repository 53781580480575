/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { AppNotificationSeverity, GenericObject } from '@dpa/ui-common';

import { ALERT_BANNER_TYPE, ROUTE_NAMES } from '@ws1c/intelligence-models';
import { NotificationActionTypes } from './notification-action-types.enum';

/**
 * NotificationConstants
 * @export
 * @class NotificationConstants
 */
export class NotificationConstants {
  public static NOTIFICATION_SEVERITY_ALERT_TYPE_MAPPING = {
    [AppNotificationSeverity.CRITICAL]: ALERT_BANNER_TYPE.DANGER,
    [AppNotificationSeverity.WARNING]: ALERT_BANNER_TYPE.WARNING,
    [AppNotificationSeverity.INFO]: ALERT_BANNER_TYPE.INFO,
  };

  public static readonly ICON_SEVERITY_SHAPES_MAP = {
    [AppNotificationSeverity.CRITICAL]: 'error-standard',
    [AppNotificationSeverity.WARNING]: 'warning-standard',
    [AppNotificationSeverity.INFO]: 'info-standard',
  };

  public static readonly ICON_SEVERITY_STATUS_MAP = {
    [AppNotificationSeverity.CRITICAL]: ALERT_BANNER_TYPE.DANGER,
    [AppNotificationSeverity.WARNING]: ALERT_BANNER_TYPE.WARNING,
    [AppNotificationSeverity.INFO]: ALERT_BANNER_TYPE.INFO,
  };

  public static readonly ACTION_ROUTE_MAP = {
    [NotificationActionTypes.ACCOUNT_SETTINGS]: ROUTE_NAMES.SETTINGS.ADMIN_ACCOUNT,
    [NotificationActionTypes.END_USER_MANAGEMENT]: ROUTE_NAMES.SETTINGS.END_USER_MANAGEMENT_NOTIFICATION_REDIRECTS,
    [NotificationActionTypes.DASHBOARD_HOME]: ROUTE_NAMES.DASHBOARD.HOME,
    [NotificationActionTypes.INCIDENT_HOME]: ROUTE_NAMES.SOLUTIONS_DEEM_INCIDENTS,
    [NotificationActionTypes.INTEGRATIONS_INBOUND]: ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS,
    [NotificationActionTypes.PROFILE]: ROUTE_NAMES.PROFILE,
    [NotificationActionTypes.REPORT_HOME]: ROUTE_NAMES.REPORT.HOME,
  };

  public static readonly ACTION_ROUTE_WITH_PARAMS_MAP = {
    [NotificationActionTypes.DASHBOARD]: (actionPageParams: GenericObject) => {
      return ROUTE_NAMES.DASHBOARD.OVERVIEW(actionPageParams.dashboard_id);
    },
    [NotificationActionTypes.INCIDENT_RCA]: (actionPageParams: GenericObject) => {
      return ROUTE_NAMES.SOLUTIONS_DEEM_INCIDENTS_RCA(actionPageParams.incident_id);
    },
    [NotificationActionTypes.INSIGHT]: (actionPageParams: GenericObject) => {
      return ROUTE_NAMES.SOLUTIONS_DEEM_INSIGHTS_ID(actionPageParams.insight_id);
    },
    [NotificationActionTypes.REPORT]: (actionPageParams: GenericObject) => {
      return ROUTE_NAMES.REPORT.OVERVIEW(actionPageParams.report_id);
    },
  };

  public static readonly ACTION_ROUTE_QUERY_PARAMS = {
    [NotificationActionTypes.END_USER_MANAGEMENT]: (page: string) => {
      return {
        queryParams: { page },
      };
    },
  };

  public static readonly NOTIFICATION_POLLING_INTERVAL = 20000;

  public static readonly READ_TIMEOUT = 2000;
}
