/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { NotificationDeliveryType, NotificationServiceDetails, NotificationTag, NotificationTagDelivery } from '@ws1c/notification/model';
import { selectNotificationState } from '@ws1c/notification/store/notification.selector';
import { NotificationState } from '@ws1c/notification/store/notification.store';
import { NotificationSettingsState } from './notification-settings.store';

/**
 * NotificationSettingsSelectors
 *
 * @export
 * @class NotificationSettingsSelectors
 */
export class NotificationSettingsSelectors {
  /**
   * getNotificationSettingsState
   * @static
   * @type {MemoizedSelector<NotificationState, NotificationSettingsState>}
   * @memberof NotificationSettingsSelectors
   */
  public static getNotificationSettingsState: MemoizedSelector<NotificationState, NotificationSettingsState> = createSelector(
    selectNotificationState,
    (state: NotificationState) => state?.notificationSettings,
  );

  /**
   * isNotificationServiceDetailsLoading
   * @static
   * @type {MemoizedSelector<NotificationState, boolean>}
   * @memberof NotificationSettingsSelectors
   */
  public static isNotificationServiceDetailsLoading: MemoizedSelector<NotificationState, boolean> = createSelector(
    NotificationSettingsSelectors.getNotificationSettingsState,
    (state: NotificationSettingsState) => state.notificationServiceDetailsLoading,
  );

  /**
   * getNotificationServiceDetails
   * @static
   * @type {MemoizedSelector<NotificationState, NotificationServiceDetails[]>}
   * @memberof NotificationSettingsSelectors
   */
  public static getNotificationServiceDetails: MemoizedSelector<NotificationState, NotificationServiceDetails[]> = createSelector(
    NotificationSettingsSelectors.getNotificationSettingsState,
    (state: NotificationSettingsState) => state.notificationServiceDetails,
  );

  /**
   * getApplicableNotificationDeliveryTypes
   * NotificationDeliveryType enum is filtered based on delivery types which we are getting from the backend in notification service details
   * Only those NotificationDeliveryType which are available in both response and enum will be applicable
   *
   * @static
   * @type {MemoizedSelector<NotificationState, NotificationDeliveryType[]>}
   * @memberof NotificationSettingsSelectors
   */
  public static getApplicableNotificationDeliveryTypes: MemoizedSelector<NotificationState, NotificationDeliveryType[]> = createSelector(
    NotificationSettingsSelectors.getNotificationServiceDetails,
    (notificationServiceDetails: NotificationServiceDetails[]) => {
      // Get all unique delivery types from NotificationServiceDetails which is updated from server
      const deliveryTypes: Set<NotificationDeliveryType> = new Set<NotificationDeliveryType>();
      notificationServiceDetails?.forEach((notificationServiceDetail: NotificationServiceDetails) => {
        notificationServiceDetail?.tags?.forEach((tag: NotificationTag) => {
          tag?.tagDeliveries?.forEach((tagDelivery: NotificationTagDelivery) => {
            deliveryTypes.add(tagDelivery.deliveryType);
          });
        });
      });
      // Filter out NotificationDeliveryType enum to include only those which are returned from API
      return Object.keys(NotificationDeliveryType)
        ?.filter((notificationDeliveryType: string) => {
          return deliveryTypes.has(NotificationDeliveryType[notificationDeliveryType]);
        })
        ?.map((notificationDeliveryType: string) => NotificationDeliveryType[notificationDeliveryType]);
    },
  );
}
