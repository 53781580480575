/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { enumConverterFactory } from '@ws1c/intelligence-models';

import { NotificationDeliveryType } from './notification-delivery-type.enum';

/**
 * NotificationTagDelivery
 *
 * @export
 * @class NotificationTagDelivery
 */
@Serializable
export class NotificationTagDelivery {
  @JsonProperty({ name: 'delivery_type', customConverter: enumConverterFactory(NotificationDeliveryType) })
  public deliveryType: NotificationDeliveryType = undefined;

  @JsonProperty('modifiable')
  public modifiable: boolean = undefined;

  @JsonProperty('selected')
  public selected: boolean = undefined;

  /**
   * Creates an instance of NotificationTagDelivery.
   * @param {...Array<Partial<NotificationTagDelivery>>} args
   * @memberof NotificationTagDelivery
   */
  constructor(...args: Array<Partial<NotificationTagDelivery>>) {
    Object.assign(this, ...args);
  }
}
