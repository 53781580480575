/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { NotificationServiceDetailsUpdateRequest } from '@ws1c/notification/model';
import { NotificationServiceDetails } from '@ws1c/notification/model/notification-service-details.model';

/**
 *
 * Define actions for Notification Settings
 * @export
 * @class NotificationSettingsActions
 */
@Injectable()
export class NotificationSettingsActions {
  public static readonly CATEGORY = '[NOTIFICATION_SETTINGS]';

  private static type = (actionName: string): string => `${NotificationSettingsActions.CATEGORY} ${actionName}`;

  public static loadNotificationSettings = createAction(NotificationSettingsActions.type('LOAD_NOTIFICATION_SETTINGS'));

  public static loadNotificationSettingsSuccess = createAction(
    NotificationSettingsActions.type('LOAD_NOTIFICATION_SETTINGS_SUCCESS'),
    props<{ notificationServiceDetails: NotificationServiceDetails[] }>(),
  );

  public static loadNotificationSettingsFailure = createAction(
    NotificationSettingsActions.type('LOAD_NOTIFICATION_SETTINGS_FAILURE'),
    props<{ error?: WebError }>(),
  );

  public static updateNotificationSettings = createAction(
    NotificationSettingsActions.type('UPDATE_NOTIFICATION_SETTINGS'),
    props<{ notificationServiceDetailsUpdateRequest: NotificationServiceDetailsUpdateRequest }>(),
  );
}
