/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { AppNotificationSeverity, JsonProperty, Serializable } from '@dpa/ui-common';

import { enumArrayConverterFactory, GenericSearchRequest, IntelligenceServiceType } from '@ws1c/intelligence-models';

/**
 * Notification Search Request
 *
 * @export
 * @class NotificationSearchRequest
 */
@Serializable
export class NotificationSearchRequest extends GenericSearchRequest {
  @JsonProperty({ name: 'severities', customConverter: enumArrayConverterFactory(AppNotificationSeverity) })
  public severities: AppNotificationSeverity[] = undefined;

  @JsonProperty({ name: 'service_types', customConverter: enumArrayConverterFactory(IntelligenceServiceType) })
  public serviceTypes: IntelligenceServiceType[] = undefined;

  /**
   * Initializes instance of NotificationSearchRequest
   * @param {Array<Partial<NotificationSearchRequest>>} args
   * @memberof NotificationSearchRequest
   */
  constructor(...args: Array<Partial<NotificationSearchRequest>>) {
    super();
    Object.assign(this, ...args);
  }
}
